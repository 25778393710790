import React, { useState, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import  {getSingleBlogAction}  from "../../redux/reducers/meta/blog/getSingleBlog";
import  {editBlogAction}  from "../../redux/reducers/meta/blog/editBlog";
import  {uploadFileAction}  from "../../redux/reducers/uploadFile";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import Header from "../../components/Header";
import JoditEditor from "jodit-react";
import { Toast } from "../../components/Toast";
import { Card, CardContent, Stack, Select,TextField, MenuItem,FormControl, InputLabel } from "@mui/material";

const EditBlog = () => {
  const [data, setData] = useState({
    title: "",
    description: "",
    readingTime: "",
    author: "",
    cover_image: "",
    blog_image: "",
    read_more_image: "",
    category: "",
    metaDescription: "",
    smallDescription: "",
    content: "",
    metaUrl: "",
    is_active:false,
  });
  const [file, setFile] = useState(null); 
  const [snake, setSnake] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogId } = useParams();
  const [imagePreviews, setImagePreviews] = useState({
    cover_image: "",
    blog_image: "",
    read_more_image: "",
  });

  const { blog, loading: fetchLoading } = useSelector((state) => state.getSingleBlog);
  const { loading: submitLoading, message, status } = useSelector((state) => state.editBlog);

  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  useEffect(() => {
    if (blogId) {
      dispatch(getSingleBlogAction(blogId));
    }
  }, [dispatch, blogId]);

  useEffect(() => {
    if (blog) {
      setData({
        title: blog?.data.title || "",
        description: blog?.data.description || "",
        readingTime: blog?.data.readingTime || "",
        author: blog?.data.author || "",
        blog_image: blog?.data.blog_image || "",
        cover_image: blog?.data.cover_image || "",
        read_more_image: blog?.data.read_more_image || "",
        category: blog?.data.category || "",
        metaDescription: blog?.data.metaDesc || "",
        smallDescription: blog?.data.smallDescription || "",
        content: blog?.data.content || "",
        is_active: blog?.data.is_active || false,
        metaUrl: blog?.data.metaUrl || "",
      });
    }
  }, [blog]);

  

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const file = files[0];
      const previewUrl = URL.createObjectURL(file);
      setImagePreviews((prev) => ({ ...prev, [name]: previewUrl }));
      uploadFile(name, file);
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const uploadFile = async (name, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await dispatch(uploadFileAction(formData));

      if (response?.payload?.status === 200) {
        // Update the corresponding field with the URL returned from the server
        setData((prev) => ({
          ...prev,
          [name]: response.payload.fileUrl,
        }));
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  
  const handleSubmit = async () => {
    try {
      const updatedData = { ...data };

      const result = await dispatch(editBlogAction({ blogId, ...updatedData }));
      if (result?.payload?.status === 200) {
        navigate("/view/all/blog");
      } else {
        console.error(result.payload.message);
      }
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };
  
  return (
    <>
      <Header />
      <div className="container-2">
        <h3>Edit Blog</h3>
        <br />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
           <CardContent>
            <h3>Title</h3>
            <JoditEditor
              ref={editor}
              value={data?.title}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, title: newContent }));
              }}
            />
          </CardContent>

          <div className="input-box">
            <label htmlFor="author">Author</label>
            <TextField
              name="author"
              onChange={handleInputChange}
              fullWidth
              value={data?.author}
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <CardContent>
            <h3>meta Description</h3>
            <JoditEditor
              ref={editor}
              value={data?.metaDescription}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, metaDescription: newContent }));
              }}
            />
          </CardContent>
          <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
            <CardContent>
              <h3>Category</h3>
              <JoditEditor
                ref={editor}
                value={data?.category}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => {
                  setData((prev) => ({ ...prev, category: newContent }));
                }}
              />
            </CardContent>
          </Card>
          <div className="input-box">
            <label htmlFor="metaUrl">meta Url</label>
            <TextField
              name="metaUrl"
              onChange={handleInputChange}
              fullWidth
              value={data?.metaUrl}
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="readingTime">Reading Time</label>
            <TextField
              fullWidth
              name="readingTime"
              onChange={handleInputChange}
              type="number"
              value={data?.readingTime}
              variant="outlined"
              size="small"
            />
          </div>
        </Stack>
        
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ gap: "15px", flexWrap: "wrap" }}
        >
          {["cover_image", "blog_image", "read_more_image"].map((imageKey) => (
            <div key={imageKey} className="input-box">
              <label htmlFor={imageKey}>{`Upload ${imageKey.replace("_", " ")}`}</label>

              {/* Image preview if available */}
              {imagePreviews[imageKey] ? (
                <img
                  src={imagePreviews[imageKey]} // Show preview if available
                  alt={imageKey}
                  style={{
                    maxWidth: "30%",
                    height: "auto",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                // Show the existing image if no preview is available
                data[imageKey] && (
                  <img
                    src={data[imageKey]} // This is the image URL from the API (default image)
                    alt={imageKey}
                    style={{
                      maxWidth: "30%",
                      height: "auto",
                      marginTop: "10px",
                      borderRadius: "8px",
                    }}
                  />
                )
              )}

              <TextField
                name={imageKey}
                id={imageKey}
                onChange={handleInputChange}
                inputProps={{ accept: "image/*" }}
                fullWidth
                type="file"
                variant="outlined"
                size="small"
              />
            </div>
          ))}

        </Stack>

        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Small Description</h3>
            <JoditEditor
              ref={editor}
              value={data?.description}
              config={config}
              tabIndex={1}
              onBlur={(newContent) =>
                setData((prev) => ({ ...prev, smallDescription: newContent }))
              }
            />
          </CardContent>
        </Card>
        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Is Active</h3>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="is-active-label">Is Active</InputLabel>
              <Select
                labelId="is-active-label"
                id="is-active"
                name="is_active"
                value={data?.is_active}
                onChange={handleInputChange}
                label="Is Active"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>

        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Content</h3>
            <JoditEditor
              ref={editor}
              value={data?.content}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, content: newContent }));
              }}
            />
          </CardContent>
        </Card>

        <div className="submit-btn-box">
          <button className="btn-submit" onClick={handleSubmit} disabled={submitLoading || fetchLoading}>
            Submit
          </button>
        </div>
        <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={status}
      />
      </div>
    </>
  );
};

export default EditBlog;
