import React, { useState } from 'react';
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import KpiLineChart from "./KpiLineChart.jsx";
import {convertMiliSecToTime} from '../../helper/agefromMilisecCal';

const TimeStats = ({data}) => {
    const [selectedMetric, setSelectedMetric] = useState('totalTimeSpentOnApp');


    const getChartData = () => {
        return data?.totalChatDateWise?.map(item => ({
            date: item.date,
            value: item[selectedMetric]
        })) || [];
    };

    return (
        <div className="live-chart-div margin-left w-100">
            <div className="line-chart-section">
                <h3>Time Stats</h3>
                <div className=''>
                    <KpiLineChart data={getChartData()} xKey="date" yKey="value" yLabel="Time Spent" />
                </div>
            </div>
            <div className='kpi-section'>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('totalTimeSpent')}>
                    <h4 className='p10'>Total Time Spent on App</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.totalTimeSpent )|| 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgTimeSpent')}>
                    <h4 className='p10'>Avg Time Spend</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.avgTimeSpent )|| 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('totalTimeSpentByMales')}>
                    <h4 className='p10'>Total Time spend by Male</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.totalTimeSpentByMales )|| 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgTimeSpentByMales')}>
                    <h4 className='p10'>Avg spend by Males</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.avgTimeSpentByMales) || 0}</p>
                </div>
            </div>
        </div>
    );
}

export default TimeStats;