import React, { useState } from 'react';
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import KpiLineChart from "./KpiLineChart.jsx";

const CallStats = ({data}) => {
    const [selectedCallType, setSelectedCallType] = useState('totalCalls');


    const getChartData = () => {
        return data?.totalChatDateWise?.map(item => ({
            date: item.date, 
            value: item[selectedCallType]
        })) || [];
    };

    return (
        <div className="live-chart-div margin-left w-100">
            <div className="line-chart-section">
                <h3>Call Stats</h3>
                <div className=''>
                    <KpiLineChart data={getChartData()} xKey="date" yKey="value" yLabel="Calls" />
                </div>
            </div>

                <div  className='kpi-section'>
                    <div className="home-top-box w-100 pointer" onClick={() => setSelectedCallType('totalCalls')}>
                        <h4 className='p10'>Total Calls Count</h4>
                        <p className="kpi-points">{data?.totalCalls || 0}</p>
                    </div>
                    <div className="home-top-box w-100 pointer" onClick={() => setSelectedCallType('maleToFemaleCalls')}>
                        <h4 className='p10'>M to F Call Count</h4>
                        <p className="kpi-points">{data?.maleToFemaleCall || 0}</p>
                    </div>
                    <div className="home-top-box w-100 pointer" onClick={() => setSelectedCallType('maleToMaleCalls')}>
                        <h4 className='p10'>M to M Call Count</h4>
                        <p className="kpi-points">{data?.maleToMaleCall || 0}</p>
                    </div>
                    <div className="home-top-box w-100 pointer" onClick={() => setSelectedCallType('femaleToFemaleCalls')}>
                        <h4 className='p10'>F to F Call Count</h4>
                        <p className="kpi-points">{data?.femaleToFemaleCall || 0}</p>
                    </div>
                </div>
        </div>
    );
}

export default CallStats;

