const calculateAgeFromMilliseconds = (birthDateInMs) => {
    if (!birthDateInMs || birthDateInMs <= 0) {
      return 'Invalid Age';
    }
  
    const currentDate = new Date();
    const birthDate = new Date(birthDateInMs);
  
    const ageInMilliseconds = currentDate - birthDate;
    
    // Convert milliseconds to years
    const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365.25; // Considering leap years
    const age = Math.floor(ageInMilliseconds / millisecondsInAYear);
  
    return age;
  };


  function convertMiliSecToTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;
  
    if (hours > 0) {
      // More than 60 minutes, show in hours and minutes
      return `${hours}h:${minutes}m`;
    } else {
      // Less than 60 minutes, show in minutes and seconds
      return `${minutes}m ${seconds}sec`;
    }
  }
  
  

export  {calculateAgeFromMilliseconds, convertMiliSecToTime};