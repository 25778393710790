import React, { useState, useRef, useMemo } from "react";
import "./style.css";
import Header from "../../components/Header";
import JoditEditor from "jodit-react";

import { Card, CardContent, CircularProgress, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction } from "../../redux/reducers/uploadFile";
import { createBlogAction } from "../../redux/reducers/meta/blog/createBlog";
import { Toast } from "../../components/Toast";

const CreateBlog = () => {
const [data, setData] = useState({});
const editor = useRef(null);
const [snake, setSnake] = useState(false);
const [file,setFile] = useState("")
const dispatch = useDispatch();
const {message, status, loading} = useSelector(state=>state.createBlog)
const { loading:imageLoading} = useSelector(state=>state.uploadFile)

const [imagePreviews, setImagePreviews] = useState({
  cover_image: "",
  blog_image: "",
  read_more_image: "",
});
const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const file = files[0];
      const previewUrl = URL.createObjectURL(file);
      setImagePreviews((prev) => ({ ...prev, [name]: previewUrl }));
      uploadFile(name, file);
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const uploadFile = async (name, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await dispatch(uploadFileAction(formData));

      if (response?.payload?.status === 200) {
        // Update the corresponding field with the URL returned from the server
        setData((prev) => ({
          ...prev,
          [name]: response.payload.fileUrl,
        }));
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // const handleSubmit = async () => {
  //   try {
  //     if (file) {
  //       const formData = new FormData();
  //       formData.set("file", file);
  //      const res =  await dispatch(uploadFileAction(formData));

  //      if(res?.payload?.status !== 200) {
  //        return
  //      }
  //      data.image = res?.payload?.fileUrl
  //   }
  //       if(Object.keys(data).length === 0 || Object.values(data).length === 0) {
  //           console.log(Object.values(data).length )
  //           return
  //       }
  //       await dispatch(createBlogAction(data))
  //       setSnake(true)

  //   } catch (error) {
  //       console.log(error)
  //   }
  // }

  const handleSubmit = async () => {
    try {
      const requiredImages = ["cover_image", "blog_image", "read_more_image"];
      const updatedData = { ...data };
  
      for (const key of requiredImages) {
        if (data[key] instanceof File) {
          const formData = new FormData();
          formData.set("file", data[key]);
          
          const res = await dispatch(uploadFileAction(formData));
          if (res?.payload?.status !== 200) {
            console.error(`Failed to upload ${key}`);
            return;
          }
  
          // Update the image URL in the data
          updatedData[key] = res?.payload?.fileUrl;
        }
      }
  
      // Ensure all fields are valid
      if (Object.values(updatedData).some((value) => !value)) {
        console.log("All fields are required.");
        return;
      }
  
      // Submit the blog data
      await dispatch(createBlogAction(updatedData));
      setSnake(true);
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="container-2">
        <h3>Create Blog</h3>
        <br />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            gap: "15px",
            flexWrap: "wrap",
            // background:"red"
          }}
        >
          <div className="input-box">
            <label htmlFor="title">Author</label>
            <TextField
              name="author"
              onChange={handleInputChange}
              fullWidth
              label="Author"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Category</label>
            <TextField
              name="category"
              onChange={handleInputChange}
              fullWidth
              label="category"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Reading Time</label>
            <TextField
              fullWidth
              name="readingTime"
              onChange={handleInputChange}
              label="Reading Time"
              type="number"
              variant="outlined"
              size="small"
            />
          </div>

          <div className="input-box">
            <label htmlFor="title">Title</label>
            <TextField
              onChange={handleInputChange}
              name="title"
              fullWidth
              label="Tilte"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Meta Description</label>
            <TextField
              onChange={handleInputChange}
              name="metaDescription"
              fullWidth
              label="Meta Description"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Meta KeyWords</label>
            <TextField
              name="metaKeyWords"
              onChange={handleInputChange}
              fullWidth
              label="Meta KeyWords"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          {["cover_image", "blog_image", "read_more_image"].map((imageKey) => (
            <div key={imageKey} className="input-box">
              <label htmlFor={imageKey}>{`Upload ${imageKey.replace("_", " ")}`}</label>

              {/* Image preview if available */}
              {imagePreviews[imageKey] ? (
                <img
                  src={imagePreviews[imageKey]} // Show preview if available
                  alt={imageKey}
                  style={{
                    maxWidth: "30%",
                    height: "auto",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                // Show the existing image if no preview is available
                data[imageKey] && (
                  <img
                    src={data[imageKey]} // This is the image URL from the API (default image)
                    alt={imageKey}
                    style={{
                      maxWidth: "30%",
                      height: "auto",
                      marginTop: "10px",
                      borderRadius: "8px",
                    }}
                  />
                )
              )}

              <TextField
                name={imageKey}
                id={imageKey}
                onChange={handleInputChange}
                inputProps={{ accept: "image/*" }}
                fullWidth
                type="file"
                variant="outlined"
                size="small"
              />
            </div>
          ))}

          
        </Stack>

        <Card
          elevation={3}
          sx={{ width: "100%", mt: 5, background: "whitesmoke" }}
        >
          <CardContent>
            <h3>Small Description</h3>
            <JoditEditor
              ref={editor}
              value={data?.smallDescription}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onBlur={(newContent) =>
                setData((prev) => ({ ...prev, smallDescription: newContent }))
              }
            />
          </CardContent>
        </Card>

        <Card
          elevation={3}
          sx={{ width: "100%", mt: 5, background: "whitesmoke" }}
        >
          <CardContent>
            <h3>Content</h3>
            <JoditEditor
              ref={editor}
              value={data?.content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // preferred to use only this option to update the content for performance reasons
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, content: newContent }));
              }}
            />
          </CardContent>
        </Card>
        <div className="submit-btn-box">
          <button
          disabled={loading}
            className="btn-submit"
            onClick={handleSubmit}
          >
          {loading  || imageLoading ? <CircularProgress size={20} color="inherit"/> : "Submit"}
          </button>
        </div>
      </div>

      <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
    </>
  );
};

export default CreateBlog;
