import React, { useState } from 'react';
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import KpiLineChart from "./KpiLineChart.jsx";
import {convertMiliSecToTime} from '../../helper/agefromMilisecCal';

const MaleFemaleTimeStats = ({data}) => {
    const [selectedMetric, setSelectedMetric] = useState('totalTimeSpentOnApp');


    const getChartData = () => {
        return data?.totalChatDateWise?.map(item => ({
            date: item.date,
            value: item[selectedMetric]
        })) || [];
    };

    return (
        <div className="live-chart-div margin-left w-100">
            <div className="line-chart-section">
                <h3>Time Stats</h3>
                <div className=''>
                    <KpiLineChart data={getChartData()} xKey="date" yKey="value" yLabel="Time Spent" />
                </div>
            </div>
            <div className='kpi-section'>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('totalTimeSpentByFemales')}>
                    <h4 className='p10'>Total Time spend by Females</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.totalTimeSpentByFemales) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgTimeSpentByFemales')}>
                    <h4 className='p10'>Avg Time spend by Female</h4>
                    <p className="kpi-points">{convertMiliSecToTime(data?.avgTimeSpentByFemales) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('notificationCount')}>
                    <h4 className='p10'>Notification click Count</h4>
                    <p className="kpi-points">{data?.notificationCount || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('friendRequestsSent')}>
                    <h4 className='p10'>Friend Requests Sent</h4>
                    <p className="kpi-points">{(data?.friendRequestsSent) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('friendRequestsAccepted')}>
                    <h4 className='p10'>Friend Requests Accepted</h4>
                    <p className="kpi-points">{data?.friendRequestsAccepted || 0}</p>
                </div>
            </div>
        </div>
    );
}

export default MaleFemaleTimeStats;