import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, Label } from "recharts";

const KpiLineChart = ({ data, xKey = "date", yKey, yLabel }) => {
  if (!data || !yKey) {
    console.error("Data or yKey is missing!");
    return null;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleString(undefined, options);
  };

  return (
    <LineChart
      width={850}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis
        dataKey={xKey}
        tickFormatter={formatDate}
      />
      <YAxis>
        <Label value={yLabel || yKey.charAt(0).toUpperCase() + yKey.slice(1)} angle={-90} position="insideLeft" />
      </YAxis>
      <Tooltip formatter={(value) => [value, yLabel]} />
      <Legend />
      <Line
        type="monotone"
        dataKey={yKey}
        stroke="#19dae1"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
};

export default KpiLineChart;