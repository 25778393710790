import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../../config/authAxios";

const initialState = { message: "", data: [], status: null, error: "" };

export const deleteBlogAction = createAsyncThunk(
    "delete-blog",
    async (id, { rejectWithValue }) => {
      try {
        const response = await authAxios.post(`/delete/blog/${id}`);
        return { id, message: response?.data?.message };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const deleteBlogSlice = createSlice({
    name: "delete-blog",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(deleteBlogAction.pending, (state) => {
        state.loading = true
      });
  
      builder.addCase(deleteBlogAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload?.data;
        state.status = action?.payload?.status;
        state.message = action?.payload?.message;
      });
  
      builder.addCase(deleteBlogAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.error;
        state.status = action?.payload?.status;
        state.message = action?.payload?.message;
      });
    },
  });
  
  export const deleteBlogReducer = deleteBlogSlice.reducer;  