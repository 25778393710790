import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBlogsAction } from "../../redux/reducers/meta/blog/getAllBlogs";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { BigLoader } from "../../components/BigLoader"; 
import {  useNavigate } from "react-router-dom";
import { deleteBlogAction } from "../../redux/reducers/meta/blog/deleteBlog";
import { Toast } from "../../components/Toast";

const ViewAllBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [snake, setSnake] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);

  const { data, loading, message, status } = useSelector((state) => state.getAllBlogs);
  const blogs = data?.blogsData || [];
  useEffect(() => {
    dispatch(getAllBlogsAction());
  }, [dispatch]);

  // const handleDelete = (id) => {
  //   dispatch(deleteBlogAction(id))
  //     .unwrap()  
  //     .then(() => {
  //       dispatch(getAllBlogsAction());
  //       setSnake(true)
  //       setDeleteMessage("Blog deleted successfully.")
  //     })
  //     .catch((error) => {
  //       console.error("Failed to delete blog:", error);
  //     });
  // };

  const handleDelete = (id) => {
    setShowConfirm(true);
    setSelectedBlogId(id);
  };

  const confirmDelete = () => {
    if (selectedBlogId) {
      dispatch(deleteBlogAction(selectedBlogId))
        .unwrap()
        .then(() => {
          dispatch(getAllBlogsAction());
          setSnake(true);
          setDeleteMessage("Blog deleted successfully.");
        })
        .catch((error) => {
          console.error("Failed to delete blog:", error);
        });
    }
    setShowConfirm(false); 
  };

  const cancelDelete = () => {
    setShowConfirm(false); 
  };


  const column = [
    {
      headerName: "Title",
      type: "",
      property: "title",
      minWidth: 80,
    },
    {
      headerName: "Meta Description",
      type: "",
      property: "metaDesc",
      minWidth: 100,
    },
    {
      headerName: "Reading Time",
      type: "",
      property: "readingTime",
      minWidth: 200,
    },
    // {
    //   headerName: "Cover Image",
    //   type: "bigImage",
    //   property: "cover_image",
    //   minWidth: 250,
    // },
    // {
    //   headerName: "Blog Image",
    //   type: "bigImage",
    //   property: "blog_image",
    //   minWidth: 250,
    // },
    // {
    //   headerName: "Read More Image",
    //   type: "bigImage",
    //   property: "read_more_image",
    //   minWidth: 250,
    // },
    {
      headerName: "Meta URL",
      type: "link",
      property: "metaUrl",
      sorting: true,
      minWidth: 200,
    },
    {
      headerName: "Description",
      type: "sentenceShort",
      property: "description",
      minWidth: 250,
    },
    {
      headerName: "Category",
      type: "",
      property: "category",
      minWidth: 250,
    },
  ];

  return (
    <>
      <Header />
      <div className="container-2">
        <div className="btn-open-div">
      <button className="filter-btn"
      onClick={()=>navigate("/create/blog")}
      >Add Blog</button>
        </div>

        {loading ? (
          <BigLoader />
          ) : (
            <>
            <Table data={blogs} column={column} redirect="_id"
            viewButton={false}
            editButton={true} 
            deleteButton={true}
            onDelete={(id) => handleDelete(id)}
            editRedirectTo="/edit/blog" />
            </>

        )}
      </div>
       {/* Confirmation Modal */}
       {showConfirm && (
        <div className="confirm-modal">
          <div className="modal-content">
            <p>Are you sure you want to delete this blog?</p>
            <button className="button-delete" onClick={confirmDelete}>Yes, Delete</button>
            <button className="button-delete" onClick={cancelDelete}>Cancel</button>
          </div>
        </div>
      )}
      <Toast open={snake} setOpen={setSnake} message={deleteMessage} 
      status={deleteMessage} />
    </>
  );
};

export default ViewAllBlog;