import React, { useState } from 'react';
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import KpiLineChart from "./KpiLineChart.jsx";

const ChatStat = ({ data }) => {
    const [selectedChatType, setSelectedChatType] = useState('totalChats');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleString(undefined, options);
      };

    const getChartData = () => {
        return data?.totalChatDateWise?.map(item => ({
            // date: item.date, 
            date:formatDate(new Date(item.date), 'dd-MM-yy'), 
            value: item[selectedChatType]
        })) || [];
    };

    return (
        <div className="live-chart-div margin-left w-100">
            <div className="line-chart-section">
                <h3>Chat Stats</h3>
                <div className=''>
                    <KpiLineChart data={getChartData()} xKey="date" yKey="value"  yLabel="Chats" yValue={selectedChatType} />
                </div>
            </div>
            <div className='kpi-section'>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedChatType('totalChats')}>
                    <h4 className='p10'>Total Chat Count</h4>
                    <p className="kpi-points">{data?.totalChats || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedChatType('maleToFemaleChats')}>
                    <h4 className='p10'>M to F Chat Count</h4>
                    <p className="kpi-points">{data?.maleToFemaleChats || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedChatType('maleToMaleChats')}>
                    <h4 className='p10'>M to M Chats Count</h4>
                    <p className="kpi-points">{data?.maleToMaleChats || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedChatType('femaleToFemaleChats')}>
                    <h4 className='p10'>F to F Chat Count</h4>
                    <p className="kpi-points">{data?.femaleToFemaleChats || 0}</p>
                </div>
            </div>
        </div>
    );
};

export default ChatStat;